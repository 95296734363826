<template>
  <div>
    <div class="w-full mt-4">
      <ListPayments :reservationId="reservationId" />
    </div>
    <div>
      <ModalNewPayment v-show="modalPayment" />
    </div>
     <div>
      <ModalViewPayment v-show="modalPaymentDetail" />
    </div>
    <div>
      <ModalEditPayment v-show="modalPaymentEdit" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ListPayments from "../components/ListPaymentsComponent";
import ModalNewPayment from "../components/ModalNewPayment.vue";
import ModalViewPayment from "../components/ModalViewPayment.vue";
import ModalEditPayment from "../components/ModalEditPayment.vue";

export default {
  components: {
    ListPayments,
    ModalNewPayment,
    ModalViewPayment,
    ModalEditPayment
  },
  data() {
    return {
      reservationId: null
    };
  },
  async beforeMount() {
    const { reservationId } = this.$route.params;
    this.reservationId = reservationId
    this.$store.commit("reservation/setReservationId", reservationId);
  },
  computed: {
    ...mapState("reservation", ["modalPayment", "modalPaymentDetail", "modalPaymentEdit"]),
  },
};
</script>