<template>
  <div>
    <div class="flex flex-row-reverse p-2">
      <div class="w-1/8">
        <vs-button
          class="m-1 mb-4"
          color="primary"
          type="filled"
          icon-pack="feather"
          @click="openDownpayments"
          >Regresar a Estado de Cuenta</vs-button
        >
      </div>
      <div class="w-1/8">
        <vs-button
          class="m-1 mb-4"
          color="success"
          type="filled"
          icon-pack="feather"
          icon="icon-plus"
          @click="openPaymentPopUp"
          >Realizar Pago</vs-button
        >
      </div>
    </div>
    <div id="data-list-thumb-view" class="data-list-container">
      <DataTable
        :headers="headers"
        :itemsData="payments"
        :itemPerPage="itemsPerPage"
        :totalItems="rowsCount"
        @serverSideMethod="loadPayments"
        :load="loadingTable"
        :showSelect="true"
        sortBy="created_at"
        :sortAsc="false"
      >
      </DataTable>
    </div>
    <div>
      <vs-popup title="Eliminación de Pago" :active.sync="popupDeletePayment">
        <vs-row>
          <h5>Se eliminará este pago. ¿Deseas eliminarlo?</h5>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              color="danger"
              @click.native="deletePayment(paymentSelected)"
              class="mt-2"
              icon-pack="feather"
              icon="icon-trash"
              >Sí, eliminar</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
    </div>
    <div>
      <vs-popup title="Confirmar Pago" :active.sync="popupConfirmPayment">
        <vs-row>
          <h3>¿Estás seguro de la confirmación del pago?</h3>
        </vs-row>
        <vs-row>
          <vs-col class="mt-2 mb-2 font-bold text-lg">
            <p>
              Cantidad de Pago: {{ getFormatCurrency(paymentSelected.amount)
              }}<br />
              No. de unidad: {{ paymentSelected.unit_number }}<br />
              No. de referencia: {{ paymentSelected.document_reference }}
            </p>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="6">
            <vs-button
              color="primary"
              @click.native="() => (popupConfirmPayment = false)"
              class="mt-2 mr-3"
              size="large"
              type="border"
              >Cancelar</vs-button
            >
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
            <vs-button
              color="primary"
              @click.native="confirmPayment(paymentSelected)"
              class="mt-2 ml-3"
              size="large"
              >Confirmar Pago</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
    </div>
    <div>
      <vs-popup title="Confirmar Pago" :active.sync="popupUnconfirmPayment">
        <vs-row>
          <h3>¿Estás seguro de anular la confirmación del pago?</h3>
        </vs-row>
        <vs-row>
          <vs-col class="mt-2 mb-2 font-bold text-lg">
            <p>
              Cantidad de Pago: {{ getFormatCurrency(paymentSelected.amount)
              }}<br />
              No. de unidad: {{ paymentSelected.unit_number }}<br />
              No. de referencia: {{ paymentSelected.document_reference }}
            </p>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="6">
            <vs-button
              color="primary"
              @click.native="() => (popupUnconfirmPayment = false)"
              class="mt-2 mr-3"
              size="large"
              type="border"
              >Cancelar</vs-button
            >
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
            <vs-button
              color="primary"
              @click.native="unconfirmPayment(paymentSelected)"
              class="mt-2 ml-3 cancel-button2"
              size="large"
              >Anular confirmación</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import { reservationApi } from "../services";
import DataTable from "../../../components/DataTable.vue";
import currencies from "../../../helpers/currencies";
import dayjs from "../../../helpers/days";

export default {
  props: ["reservationId"],
  components: {
    DataTable,
  },
  data() {
    return {
      itemsPerPage: 10,
      popupDeletePayment: false,
      popupConfirmPayment: false,
      popupUnconfirmPayment: false,
      paymentSelected: "",
      payments: [],
      headers: [],
      rowsCount: 0,
      loadingTable: true,
    };
  },
  async beforeMount() {
    this.headers = [
      {
        text: "Monto Pagado",
        value: "amount",
        align: "left",
        summaryType: "sum",
        filterType: "Number",
        filterValue: { type: "", value: "" },
        filter: true,
        cellRenderer: "composed",
        cellRendererParams: (params) => this.getFormatCurrency(params.amount),
        width: 150,
      },
      {
        text: "Fecha",
        value: "paid_on",
        align: "left",
        cellRenderer: "composed",
        cellRendererParams: (params) =>
          dayjs(params.paid_on).format("DD/MM/YY"),
        filterType: "Date",
        filterValue: { type: null, value: null },
        filter: true,
        width: 150,
      },
      {
        text: "No. Referencia",
        value: "document_reference",
        align: "left",
        filterValue: { type: null, value: "" },
        filter: false,
        width: 200,
      },
      {
        text: "Tipo de Pago",
        value: "payment_method.name",
        align: "left",
        cellRenderer: "composed",
        cellRendererParams: (params) => params.payment_method.name,
        filterValue: { type: null, value: "" },
        filter: true,
        width: 200,
      },
      {
        text: "Acciones",
        value: "actions",
        align: "left",
        cellRenderer: "actions",
        cellRendererParams: (params) => {
          const buttons = [];
          buttons.push({
            color: "primary",
            icon: "icon-eye",
            title: "ver",
            event: () => {
              this.viewPayment(params);
            },
          });
          if (!params.canceled && !params.confirmed) {
            buttons.push({
              color: "primary",
              icon: "icon-edit",
              title: "editar",
              event: () => {
                this.editPayment(params);
              },
            });

            buttons.push({
              color: "danger",
              icon: "icon-trash",
              title: "eliminar",
              event: () => {
                this.popupDeletePayment = true;
                this.paymentSelected = params;
              },
            });
          }
          return buttons;
        },
        sortable: false,
        width: 200,
      },
      {
        text: "Revision",
        value: "revition",
        align: "left",
        cellRenderer: "actions",
        cellRendererParams: (params) => {
          const buttons = [];
          if (!params.confirmed && !params.canceled) {
            buttons.push({
              color: "primary",
              label: "Confirmar Pago",
              title: "confirmar",
              class: "confirm-button mr-2",
              event: () => {
                this.popupConfirmPayment = true;
                this.paymentSelected = params;
              },
            });
          } else if (params.confirmed && !params.canceled) {
            buttons.push({
              color: "primary",
              label: "Pago Confirmado",
              title: "confirmado",
              class: "confirm-button mr-2",
              disabled: true,
              event: () => {},
            });
            buttons.push({
              color: "warning",
              label: "Desconfirmar",
              title: "anular",
              class: "cancel-button mr-2",
              event: () => {
                this.popupUnconfirmPayment = true;
                this.paymentSelected = params;
              },
            });
          } else {
            buttons.push({
              color: "danger",
              label: "Anulado",
              title: "cancelado",
              class: "anuled-button mr-2",
              disabled: true,
              event: () => {},
            });
          }
          return buttons;
        },
        sortable: false,
        width: 300,
      },
    ];
  },
  async mounted() {
    this.$root.$on("reload-payment-list", async () => {
      this.loadPayments({
        ordering: "order_by: {}",
        pagination: ", limit:10, offset: 0",
        filtering: "",
      });
    });
    this.rowsCount = await reservationApi.getPaymentsTotal(this.reservationId);
  },
  methods: {
    async loadPayments(options) {
      try {
        const reserveId = this.reservationId;
        this.loadingTable = true;
        const { ordering, pagination, filtering } = options;
        this.payments = await reservationApi.getPayments(
          reserveId,
          ordering,
          pagination,
          filtering
        );

        this.rowsCount = await reservationApi.getPaymentsTotal(
          this.reservationId,
          filtering
        );
        this.loadingTable = false;
      } catch (e) {
        console.log(e);
      }
    },
    openPaymentPopUp() {
      this.$store.commit("reservation/setModalPayment", true);
    },
    async deletePayment(payment) {
      try {
        await reservationApi.deletePaymentById(payment.id, this.reservationId);
        this.popupDeletePayment = false;

        this.$vs.notify({
          time: 6000,
          title: `Pago No. ${payment.no} 🏢🚀.`,
          text: `Eliminado correctamente.`,
          color: "success",
        });
        this.$root.$emit("reload-payment-list");
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible eliminar el pago`,
          text: `No ha sido posible eliminar el pago`,
          color: "danger",
        });
      }
    },
    async confirmPayment(payment) {
      try {
        await reservationApi.confirmPayment(payment.id);
        this.popupConfirmPayment = false;
        this.$vs.notify({
          time: 6000,
          title: `Pago No. ${payment.no} 🏢🚀.`,
          text: `Confirmado correctamente.`,
          color: "success",
        });
        this.$root.$emit("reload-payment-list");
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible confirmar el pago`,
          text: `No ha sido posible confirmar el pago`,
          color: "danger",
        });
      }
    },
    async unconfirmPayment(payment) {
      try {
        await reservationApi.unconfirmPayment(payment.id);
        this.popupUnconfirmPayment = false;
        this.$vs.notify({
          time: 6000,
          title: `Pago No. ${payment.no} 🏢🚀.`,
          text: `Desconfirmado correctamente.`,
          color: "success",
        });
        this.$root.$emit("reload-payment-list");
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible desconfirmar el pago`,
          text: `No ha sido posible desconfirmar el pago`,
          color: "danger",
        });
      }
    },
    editPayment(data) {
      this.$store.commit("reservation/setModalPaymentEdit", true);
      this.$store.commit("reservation/setPaymentId", data.id);
    },
    viewPayment(data) {
      this.$store.commit("reservation/setReservationId", this.reservationId);
      this.$store.commit("reservation/setPaymentId", data.id);
      this.$store.commit("reservation/setModalPaymentDetail", true);
    },
    openDownpayments() {
      this.$router.push({
        name: "Cartera",
        params: {
          id: this.reservationId,
        },
      });
    },
    getFormatCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];
      return currencies.currencyFormatter({ value, currency: currencySymbol });
    },
  },
};
</script>
<style>
.confirm-button {
  background-color: #cedff8 !important;
  border: 0 !important;
  color: #1973e8 !important;
  font-weight: 600 !important;
  font-size: small !important;
  width: 130px !important;
}
.confirm-button:disabled {
  background-color: #1973e8 !important;
  opacity: 1;
  color: #ffffff !important;
}
.cancel-button {
  background-color: #efeabd !important;
  border: 0 !important;
  color: #ebb41b !important;
  font-weight: 600 !important;
  font-size: small !important;
  width: 130px !important;
}
.anuled-button {
  background-color: red !important;
  border: 0 !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: small !important;
  width: 130px !important;
}
.cancel-button2 {
  background-color: #ebb41b !important;
  border: 0 !important;
  color: white !important;
}
</style>
