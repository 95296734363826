<template>
  <div>
    <div
      class="fixed z-10 inset-0 overflow-y-auto mt-24 md:mt-4"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="block absolute top-0 right-0 pt-4 pr-4">
            <button
              @click="toggleUploadPayment(false)"
              type="button"
              class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span class="sr-only">Close</span>
              <svg
                class="h-10 w-10 hover:text-danger"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <!-- IF -->
            <div class="sm:flex sm:items-start">
              <div class="text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-2xl leading-6 font-medium text-gray-900 mt-2"
                  id="modal-title"
                >
                  Editar Pago
                </h3>
              </div>
            </div>

            <div>
              <vs-row>
                <vs-col class="sm:w-full lg:w-1/2 p-1">
                  <div class="w-full sm:w-full mt-2">
                    <vs-input
                      size="medium"
                      class="w-full px-2"
                      label="Monto"
                      color="primary"
                      v-model="userPaymentAmount"
                      v-validate="'required'"
                      name="price"
                      id="price"
                      type="number"
                    />
                  </div>
                </vs-col>
                
                <vs-col class="sm:w-full lg:w-1/2 p-1">
                  <p class="p-2">Documento de Soporte:</p>
                  <v-select
                    label="name"
                    :options="paymentMethod"
                    v-model="paymentMethodSelected"
                  ></v-select>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col class="sm:w-full lg:w-1/2 p-1">
                  <div class="w-full sm:w-full mt-2">
                    <vs-input
                      size="medium"
                      class="w-full px-2"
                      label="No. de Referencia"
                      color="primary"
                      v-model="documentReference"
                      v-validate="'required'"
                      name="document_referencia"
                    />
                  </div>
                </vs-col>
                <vs-col class="sm:w-full lg:w-1/2 p-1">
                  <div class="w-full sm:w-full mt-2">
                    <vs-input
                      type="date"
                      size="medium"
                      class="w-full px-2"
                      label="Fecha de Pago"
                      color="primary"
                      v-model="paidOn"
                      v-validate="'required'"
                      name="paid-on"
                    />
                  </div>
                </vs-col>
              </vs-row>
              <vs-row class="mt-6">
                <vs-col class="flex sm:w-full px-2">
                  <div class="w-full sm:w-full mb-3">
                    <p>
                      <span>Descripción</span>
                      <span class="text-red-500"> *</span>
                    </p>
                    <vs-textarea
                      counter="250"
                      placeholder="Descripción"
                      v-model="paymentDescription"
                      height="350"
                    />
                  </div>
                </vs-col>
              </vs-row>
              <vs-row class="mt-6">
                <vs-col class="flex sm:w-full px-2">
                  <div class="w-full sm:w-full mb-3">
                    <FileImporter :onSuccess="onloadFile" />
                  </div>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col class="sm:w-full lg:w-1/2 p-1">
                  <div class="w-full sm:w-full mt-2">
                    <button
                    type="button"
                    ref="loadableButton"
                    id="button-with-loading"
                    class="vs-con-loading__container w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    @click="submitPayment"
                  >
                    Confirmar
                  </button>
                  </div>
                </vs-col>
                <vs-col class="sm:w-full lg:w-1/2 p-1">
                  <div class="w-full sm:w-full mt-2">
                <button
                @click="toggleUploadPayment(false)"
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-full sm:text-xl"
              >
                Cancelar
              </button>
               </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import projectApi from "../../projects/services/project.api";
import S3 from "aws-s3";
import vSelect from "vue-select";
import FileImporter from "../../../components/FileImporter.vue";
import { reservationApi, paymentMethodApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
import slugify from "../../../helpers/slugify";

export default {
  components: {
    FileImporter,
    vSelect
  },
  data() {
    return {
      project: null,
      paymentDescription: null,
      paymentDocumentFile: null,
      paymentMethod: [],
      paymentMethodSelected: null,
      paymentAmount: 0,
      documentReference: null,
      paidOn: null,
      moduleError: false,
      newFile: false
    };
  },
  async mounted() {
    const projectId = this.$store.getters["project/currentProject"];
    this.project = await projectApi.get(projectId);
    await this.loadPayment(this.paymentId)
  },
  computed: {
    ...mapState("reservation", ["reservationId", "paymentId"]),
    userPaymentAmount() {
        return this.paymentAmount.toFixed(2);
    },
    fieldsReady() {
      if (
        this.paymentAmount &&
        this.paymentDocumentFile &&
        this.paymentMethodSelected
      ) {
        return true;
      }
      return false;
    },
    config() {
      const projectName = slugify(this.project.name);
      const developerName = slugify(this.project.property_developer.name);

      return {
        bucketName: "flattlo-app",
        dirName: `${developerName}/projects/${projectName}/payments`,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SK,
        s3Url: process.env.VUE_APP_S3_URL
      };
    },
    S3Client() {
      return new S3(this.config);
    }
  },
  watch: {
    async paymentId(value){
      await this.loadPayment(value)
    }
  },
  methods: {
    toggleUploadPayment(v) {
      this.paymentDescription = null;
      this.paymentDocumentFile = null;
      this.paymentMethodSelected = null;
      this.documentReference = null;
      this.paidOn = null;
      this.newFile = false;
      this.$store.commit("reservation/setModalPaymentEdit", v);
      this.$store.commit("reservation/setPaymentId", null);
    },
    onloadFile(file) {
      this.paymentDocumentFile = file;
      this.newFile = true
    },
    async submitPayment() {
      try {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: "#button-with-loading",
          scale: 0.5
        });
        
        
        let uploadImage = null
        if(this.newFile){
          const fileName = Date.now();
          uploadImage = await this.S3Client.uploadFile(
            this.paymentDocumentFile,
            fileName
          );
        }


        const payment = {
          reserveId: this.reservationId,
          paymentId: this.paymentId,
          amount: parseFloat(this.paymentAmount),
          description: this.paymentDescription ? this.paymentDescription : null,
          voucherUrl: this.newFile ? uploadImage.location : this.paymentDocumentFile,
          paymentMethod: this.paymentMethodSelected.id,
          documentReference: this.documentReference
            ? this.documentReference
            : null,
          paidOn: this.paidOn
        };
        
        await reservationApi.updPayment(payment);

        this.$vs.notify({
          time: 6000,
          title: `Pago 🏢🚀.`,
          text: `Modificado correctamente.`,
          color: "success"
        });

        this.$store.commit("reservation/setModalPaymentEdit", false);
        this.$store.commit("reservation/setPaymentId", null);
        
        this.$root.$emit("reload-payment-list");


        this.paymentDescription = null;
        this.paymentDocumentFile = null;
        this.paymentMethodSelected = null;
        this.documentReference = null;
        this.paidOn = null;
        this.newFile = false
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        
        

      } catch (e) {
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        sentryCaptureException(e, "upload-payment");
        this.$vs.notify({
          title: `Error`,
          text: `Ha ocurrido un almacenar pago.`,
          color: "danger"
        });
      }
    },
    async loadPayment(value){
      if(value != null){
        this.paymentMethod = await paymentMethodApi.list();
        const payment = await reservationApi.getPaymentById(value);

        let paid_on = new Date(payment.paid_on)
        
        const dd = String(paid_on.getDate()).padStart(2, '0');
        const mm = String(paid_on.getMonth() + 1).padStart(2, '0');
        const yyyy = paid_on.getFullYear();

        paid_on = yyyy + '-' + mm + '-' + dd;

        this.paymentAmount = payment.amount
        this.paymentDescription = payment.description
        this.paymentMethodSelected = payment.payment_method
        this.documentReference = payment.document_reference
        this.paidOn = paid_on
        this.paymentDocumentFile = payment.document_url
      }
    }
  }
};
</script>

<style lang="scss">
.fixed.inset-0.overflow-y-auto {
  z-index: 53000 !important;
}
</style>
